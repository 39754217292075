li {
	text-decoration: none;
}
.clearfix {
	display: inline-block;
	text-decoration: none;
}
.clearfix ul  
{
	list-style:none;
    text-decoration: none;
}
.pagination-status {
	display: block;
	padding: 0rem 0rem;
	text-decoration: none;
	color: currentColor;
	border-radius: 0.1rem;
	text-decoration: none !important;
}
  
li.pagination-status__item {
	color: black;
	float: left;
	padding: 5px 10px;
}

.pagination-status li.active {
	background-color: #4CAF50;
	color: blue;
}

.pagination-status li:hover:not(.active) {background-color: #ddd;}